import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import { GET_USER_BY_ID } from '../../graphql/users/queries/getUserById';
import { DateTime } from 'luxon';
import { Tabs, Tab, Box } from '@mui/material';
import { useTheme } from '@mui/material';
import TabTable from '../../components/Tables/TabTable';
import BuildBreadcrumbs from '../../components/buildBreadcrumbs/buildBreadcrumbs';
import { GET_ALL_DATE_EVENTS_BY_ID } from '../../graphql/dateEvents/queries/getDateEventsByUser';
import formatDate from '../../utils/FormatDate';
import Spinner from '../../components/Spinner';
import { GET_PTO_DATA } from '../../graphql/users/queries/getPTOHoursRemaining';
import { formatNumberWithDecimal } from '../../helpers/formattingAccruedBalance';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const UserInfo = styled(Typography)`
  flex-direction: column;
  padding: 0.5rem;
  padding-right: 0;
  text-align: left;
`;

const StyledTab = styled(Tab)({
  fontSize: '1rem',
  padding: '0.5rem',
  minWidth: 'auto',
});

const UserPage: FC = () => {
  const { id } = useParams<{ id: string | undefined }>();
  const numId = id ? parseInt(id, 10) : undefined;
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { caxyGreen } = theme.colors;
  const { data: user } = useQuery(GET_USER_BY_ID, {
    variables: {
      id: numId,
    },
  });

  const { data: dateEvents } = useQuery(GET_ALL_DATE_EVENTS_BY_ID, {
    variables: {
      userId: numId,
    },
  });

  const {
    data: ptoHoursAvailable,
    loading,
    error,
  } = useQuery(GET_PTO_DATA, {
    variables: {
      id: numId,
    },
  });

  const [activeTab, setActiveTab] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    if (error) {
      navigate(`/404`);
    }
  }, [error, navigate]);
  if (loading) return <Spinner />;

  const formattedDate = formatDate(user?.userById.startDate);

  const today = DateTime.now();
  const startDate = user?.userById?.startDate
    ? DateTime.fromISO(user?.userById.startDate.toString())
    : today;
  const anniversaryThisYear = DateTime.fromObject({
    month: startDate.month,
    day: startDate.day,
    year: today.year, // Use current year for comparison
  });

  // If the anniversary has already passed this year, calculate for next year's anniversary
  const nextAnniversary =
    anniversaryThisYear < today ? anniversaryThisYear.plus({ years: 1 }) : anniversaryThisYear;
  const daysUntilAnniversary = Math.floor(nextAnniversary.diff(today, 'days').days);

  const crumbs = `/users/${user?.userById.username}`.split('/');

  return (
    <>
      <BuildBreadcrumbs path={crumbs} isBreadcrumbsRendered={true} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          boxShadow: '1rem 1rem 2rem #00000033',
          borderRadius: '0.3rem',
          marginTop: '2rem',
          backgroundColor: 'white',

          '@media (min-width: 20rem)': {
            width: '19.5rem',
            padding: 0,
          },
          '@media (min-width: 26.875rem)': {
            width: '24rem',
            padding: '1rem',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            width: { xs: '20rem', sm: '28rem' },
          }}
        >
          <Grid
            container
            spacing={1}
            sx={{
              flexDirection: isMobile ? 'column' : 'row',
              margin: 0,
              '@media (min-width: 26.875rem)': {
                marginLeft: '-2rem',
              },
              '@media (min-width: 37.5rem)': {
                marginLeft: '1.5rem',
              },
            }}
          >
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Box sx={{ textAlign: 'left', width: '100%', padding: 0 }}>
                {' '}
                {user && (
                  <>
                    <UserInfo>
                      <strong>Name:</strong> {user.userById.username}
                    </UserInfo>
                    <UserInfo>
                      <strong>Display Name:</strong> {user.userById.displayName}
                    </UserInfo>
                    <UserInfo>
                      <strong>Start Date:</strong> {formattedDate}
                    </UserInfo>
                    <UserInfo>
                      <strong>Days Until PTO Reset:</strong> {daysUntilAnniversary}
                    </UserInfo>
                    <UserInfo>
                      <strong>Unpaid Hours Taken:</strong>{' '}
                      {formatNumberWithDecimal(user.userById.unpaidHours)}
                    </UserInfo>
                  </>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Box sx={{ textAlign: 'left', width: '100%', marginTop: { xs: -1, sm: 0 } }}>
                {' '}
                {user && (
                  <>
                    <UserInfo>
                      <strong>PTO Available:</strong>{' '}
                      {formatNumberWithDecimal(ptoHoursAvailable?.ptoData.totalRemainingHours)}
                    </UserInfo>
                    <UserInfo>
                      <strong>Flex Remaining:</strong> {user?.userById.flexDays}
                    </UserInfo>
                    <UserInfo>
                      <strong>WFH Remaining:</strong> {user?.userById.wfhDays}
                    </UserInfo>
                    <UserInfo>
                      <strong>Exception Time:</strong> {user?.userById.exceptionDays}
                    </UserInfo>
                    <UserInfo>
                      <strong>PTO Accrued:</strong>{' '}
                      {formatNumberWithDecimal(user?.userById.ptoHours)}
                    </UserInfo>
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Tabs
          value={activeTab}
          onChange={handleChange}
          sx={{
            '& .MuiTabs-indicator': {
              backgroundColor: caxyGreen,
            },
            marginLeft: { sm: -15 },
            '& .Mui-selected': {
              color: 'black',
            },
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            '& .MuiTabs-flexContainer': {
              justifyContent: 'flex-start',
            },
            '@media (min-width: 26.875rem)': {
              marginLeft: '-4rem',
            },
            '@media (min-width: 37.5rem)': {
              marginLeft: '-4.75rem',
            },
          }}
        >
          <StyledTab label='PTO' />
          <StyledTab label='Flex' />
          <StyledTab label='WFH' />
          <StyledTab label='Exception' />
          <StyledTab label='Unpaid' />
        </Tabs>

        <CustomTabPanel value={activeTab} index={0}>
          <TabTable data={dateEvents?.dateEventByUserId} type='pto' />
        </CustomTabPanel>
        <CustomTabPanel value={activeTab} index={1}>
          <TabTable data={dateEvents?.dateEventByUserId} type='flex' />
        </CustomTabPanel>
        <CustomTabPanel value={activeTab} index={2}>
          <TabTable data={dateEvents?.dateEventByUserId} type='wfh' />
        </CustomTabPanel>
        <CustomTabPanel value={activeTab} index={3}>
          <TabTable data={dateEvents?.dateEventByUserId} type='exception' />
        </CustomTabPanel>
        <CustomTabPanel value={activeTab} index={4}>
          <TabTable data={dateEvents?.dateEventByUserId} type='unpaid' />
        </CustomTabPanel>
      </Box>
    </>
  );
};

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

export default UserPage;
